import React from "react";
// import React, { useState } from 'react';
import { motion } from "framer-motion";


const About = () => {
    const aboutAnimation = {
        initial: { x: '100vw', opacity: 0 },
        animate: { x: 0, opacity: 1, transition: { duration: 1 } },
        exit: { x: '-100vw', opacity: 0, transition: { duration: 1 } }
    };
    return (
        <motion.div
            variants={aboutAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
            data-cursor-color="rgb(255, 255, 255,0.2)"
        >
            <section className="about">
                <div className="aboutme">
                    <div className="intro"><h1>Fares Kouki | Full Stack Developer | Web & Software Solutions</h1></div>
                    <a className="middle" href="Fares_Kouki_FR.pdf" download="Fares_Kouki_FR.pdf">Download my Resume</a>
                    <a href="mailto:faresk1407@gmail.com" className="outro">Drop me an Email</a>
                </div>
            </section>
        </motion.div >
    );
}
export default About;