import React, { useState } from 'react';
import { motion } from "framer-motion";


const Others = () => {

    const othersAnimation = {
        initial: { x: '100vw', opacity: 0 },
        animate: { x: 0, opacity: 1, transition: { duration: 1 } },
        exit: { x: '-100vw', opacity: 0, transition: { duration: 1 } }
    };
    const [isFlipped1, setIsFlipped1] = useState(false);
    const [isFlipped2, setIsFlipped2] = useState(false);
    const [isFlipped3, setIsFlipped3] = useState(false);
    const [isFlipped4, setIsFlipped4] = useState(false);
    const [isFlipped5, setIsFlipped5] = useState(false);
    const [isFlipped6, setIsFlipped6] = useState(false);
    const [isFlipped7, setIsFlipped7] = useState(false);
    const [isFlipped8, setIsFlipped8] = useState(false);
    const [isFlipped9, setIsFlipped9] = useState(false);
    const [isFlipped10, setIsFlipped10] = useState(false);
    const [isFlipped11, setIsFlipped11] = useState(false);

    const handleCardClick1 = () => {
        setIsFlipped1(!isFlipped1);
    };
    const handleCardClick2 = () => {
        setIsFlipped2(!isFlipped2);
    };
    const handleCardClick3 = () => {
        setIsFlipped3(!isFlipped3);
    };
    const handleCardClick4 = () => {
        setIsFlipped4(!isFlipped4);
    };
    const handleCardClick5 = () => {
        setIsFlipped5(!isFlipped5);
    };
    const handleCardClick6 = () => {
        setIsFlipped6(!isFlipped6);
    };
    const handleCardClick7 = () => {
        setIsFlipped7(!isFlipped7);
    };
    const handleCardClick8 = () => {
        setIsFlipped8(!isFlipped8);
    };
    const handleCardClick9 = () => {
        setIsFlipped9(!isFlipped9);
    };
    const handleCardClick10 = () => {
        setIsFlipped10(!isFlipped10);
    };
    const handleCardClick11 = () => {
        setIsFlipped11(!isFlipped11);
    };

    return (


        // <div className='others'>
        <motion.div
            variants={othersAnimation}
            initial="initial"
            animate="animate"
            exit="exit"
            data-cursor-color="rgb(255, 255, 255,0.2)"
        >
            <section className='others'>
                <div className='gridofprojects'>
                    <article className={`project project1 ${isFlipped1 ? 'flipped' : ''}`} onClick={handleCardClick1}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">
                                    <div className="texte-projet">
                                        <p>Plateforme de Gestion d'utilisateurs pour EELV (Europe Ecologie les Verts)r</p>
                                    </div>
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://base.eelv.fr">La BASE</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                        <div className="technologies-utilisees">
                                            Python(Pyramid)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </article>
                    <article className={`project project2 ${isFlipped2 ? 'flipped' : ''}`} onClick={handleCardClick2}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">
                                    <div className="texte-projet">
                                        <p>Plateforme de Gestion d'utilisateurs et de Paiements pour L'APAS ONF</p>
                                    </div>
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://apasonf.fr">Yggdrasil</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                        <div className="technologies-utilisees">
                                            PHP (Symfony)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className={`project project3 ${isFlipped3 ? 'flipped' : ''}`} onClick={handleCardClick3}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">
                                    <div className="texte-projet">
                                        <p>Edition de Thèmes, de Plugins et de Design pour La Télé Libre</p>
                                    </div>
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://latelelibre-cards.enprojet.xyz">La Télé Libre</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                        <div className="technologies-utilisees">
                                            PHP / Wordpress / JS
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className={`project project4 ${isFlipped4 ? 'flipped' : ''}`} onClick={handleCardClick4}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://github.com/Faressj/MyMeetic.git">MyMeetic</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className={`project project5 ${isFlipped5 ? 'flipped' : ''}`} onClick={handleCardClick5}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">
                                    <div className="texte-projet">
                                        <p>Edition de Thèmes, de Plugins et de Design pour Les Sardines à Vélo</p>
                                    </div>
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://sardines.enprojet.xyz">Les Sardines</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className={`project project6 ${isFlipped6 ? 'flipped' : ''}`} onClick={handleCardClick6}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://github.com/Faressj/MyCinema">MyCinéma</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </article>
                    <article className={`project project7 ${isFlipped7 ? 'flipped' : ''}`} onClick={handleCardClick7}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">

                                    <div className="texte-projet">
                                        <p>Plusieeurs petits projets d'Algorithmie</p>
                                    </div>
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://github.com/stars/Faressj/lists/algos">Algos</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className={`project project8 ${isFlipped8 ? 'flipped' : ''}`} onClick={handleCardClick8}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">


                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://github.com/Faressj/Voyagerz">Voyagerz</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                        <div className="technologies-utilisees">
                                            ReactJS
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className={`project project9 ${isFlipped9 ? 'flipped' : ''}`} onClick={handleCardClick9}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">


                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://github.com/Faressj/WeBreathe">WeBreathe</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className={`project project10 ${isFlipped10 ? 'flipped' : ''}`} onClick={handleCardClick10}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://github.com/Faressj/my_quizz">MyQuizz</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className={`project project11 ${isFlipped11 ? 'flipped' : ''}`} onClick={handleCardClick11}>
                        <div className="card-front"></div>
                        <div className="card-back">
                            <div className='insidecardback'>
                                <div className="parent">
                                    <div className='insideinside'>
                                        <div className="titre-projet">
                                            <div className='insidetitreproject'>
                                                <a href="https://github.com/Faressj/MyMuseum">Museum</a>
                                                <p>(Click)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </motion.div >


    )
}
export default Others;