import React, { useEffect, useRef, useState } from "react";
import "./css/Home.scss";
import { motion } from "framer-motion";

const Home = () => {
  const [imageBase641, setImageBase641] = useState("");
  const canvasRef = useRef(null);

  // Diagonal Scroll Part
  useEffect(() => {
    // Scroll part
    const container = document.querySelector("[data-container]");
    const handleScroll = () => {
      let horizontalTranslation =
        (window.pageYOffset / window.innerHeight) * window.innerWidth;

      container.style.transform = `
                translate(-${horizontalTranslation}px,
                ${-Math.floor(window.pageYOffset)}px)
            `;
    };
    const cards = document.querySelectorAll(".card");
    cards.forEach((card, index) => {
      let mleft = index * 50 - 40;
      let mtop = index * 100;
      card.style.marginLeft = `${mleft}vw`;
      card.style.marginTop = `${mtop + 10}vh`;
    });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Rain Particles Effect
  useEffect(() => {
    // Fetch part
    fetch("/images/img1.txt")
      .then((response) => response.text())
      .catch((error) => console.error("Error fetching img1:", error))
      .then((image) => setImageBase641(image));
  }, []);
  useEffect(() => {
    // Canvas part
    if (imageBase641 && canvasRef.current) {
      let mappedImage = [];
      // const canvas = document.getElementById('canvas1');
      const canvas = canvasRef.current;

      const ctx = canvas.getContext("2d");
      const myImage = new Image();
      myImage.src = imageBase641;
      myImage.onload = () => {
        let width = window.innerWidth * 0.8;
        let height = window.innerHeight * 0.8;

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(myImage, 0, 0, canvas.width, canvas.height);

        const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (let y = 0; y < canvas.height; y++) {
          let row = [];
          for (let x = 0; x < canvas.width; x++) {
            const red = pixels.data[y * 4 * pixels.width + x * 4];
            const green = pixels.data[y * 4 * pixels.width + (x * 4 + 1)];
            const blue = pixels.data[y * 4 * pixels.width + (x * 4 + 2)];
            const brightness = calculateRelativeBrightness(red, green, blue);
            const cell = [brightness];
            row.push(cell);
          }
          mappedImage.push(row);
        }

        initParticles();
        animate();
      };

      let particlesArray = [];
      const numberOfParticles = 5000;

      function calculateRelativeBrightness(red, green, blue) {
        return (
          Math.sqrt(
            red * red * 0.299 + green * green * 0.587 + blue * blue * 0.114
          ) / 100
        );
      }

      class Particle {
        constructor() {
          this.x = Math.random() * canvas.width;
          this.y = 0;
          this.speed = 0;
          this.velocity = Math.random() * 0.5;
          this.size = Math.random() * 1.5 + 1;
          this.position1 = Math.floor(this.y);
          this.position2 = Math.floor(this.x);
        }
        update() {
          this.position1 = Math.floor(this.y);
          this.position2 = Math.floor(this.x);
          this.speed = mappedImage[this.position1][this.position2][0];
          let movement = 2.5 - this.speed + this.velocity;
          this.y += movement;
          if (this.y >= canvas.height) {
            this.y = 0;
            this.x = Math.random() * canvas.width;
          }
        }
        draw() {
          ctx.beginPath();
          ctx.fillStyle = "white";
          ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
          ctx.fill();
        }
      }

      function initParticles() {
        for (let i = 0; i < numberOfParticles; i++) {
          particlesArray.push(new Particle());
        }
      }

      function animate() {
        ctx.globalAlpha = 0.05;
        ctx.fillStyle = "rgb(0,0,0)";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.globalAlpha = 0.2;
        for (let i = 0; i < particlesArray.length; i++) {
          particlesArray[i].update();
          ctx.globalAlpha = particlesArray[i].speed * 0.5;
          particlesArray[i].draw();
        }
        requestAnimationFrame(animate);
      }
    }
  }, [imageBase641]);

  const homeAnimation = {
    initial: { x: "-100vw", opacity: 0 },
    animate: { x: 0, opacity: 1, transition: { duration: 1 } },
    exit: { x: "100vw", opacity: 0, transition: { duration: 1 } },
  };
  const [isFlipped1, setIsFlipped1] = useState(false);

  const handleCardClick1 = () => {
    setIsFlipped1(!isFlipped1);
  };
  const [isFlipped2, setIsFlipped2] = useState(false);

  const handleCardClick2 = () => {
    setIsFlipped2(!isFlipped2);
  };
  const [isFlipped3, setIsFlipped3] = useState(false);

  const handleCardClick3 = () => {
    setIsFlipped3(!isFlipped3);
  };

  return (
    <motion.div
      variants={homeAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <section className="containerparent">
        <div className="container" data-container>
          <div className="cards" data-cards>
            <div className="container2">
              <div className="card" data-cursor-color="rgb(255, 255, 255,0.8)">
                <div className="intro">
                  <h1>Fares Kouki</h1>
                  <h2>Full Stack Web Developer</h2>
                  <h3>(Click on my Projects)</h3>
                  <svg
                    className="scroll-down-indicator"
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="60"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g className="arrows">
                      <path d="M19 12l-7 7-7-7" />
                      <path className="second-arrow" d="M19 18l-7 7-7-7" />


                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div className="container2" onClick={handleCardClick1}>
              <div
                onClick={handleCardClick1}
                data-cursor-color="rgb(255, 255, 255,0.8)"
                className={`card ${isFlipped1 ? "flipped" : ""}`}
              >
                <div className="card-front">
                  <canvas
                    ref={canvasRef}
                    id="canvas1"
                    className="insidecardfront"
                  ></canvas>
                </div>
                <div className="card-back">
                  <div className="insidecardback">
                    <div className="parent">
                      <div className="texte-projet">
                        <h4>
                          Ceci est mon premier portfolio créé avec BabylonJS,
                          une plateforme puissante pour le développement 3D sur
                          le web. Ce projet spécifique met en scène un Système
                          Solaire interactif, conçu non seulement pour démontrer
                          mes compétences en matière de programmation et de
                          conception 3D, mais aussi pour approfondir ma
                          compréhension des technologies 3D avancées. En
                          travaillant sur ce projet, j'ai pu explorer divers
                          aspects de BabylonJS, y compris la modélisation
                          d'environnements spatiaux, la simulation de mouvements
                          planétaires et la création d'interactivités
                          utilisateur immersives. Ce portfolio est le reflet de
                          mon engagement envers l'apprentissage continu et ma
                          passion pour la technologie 3D, et il représente un
                          jalon important dans mon parcours de développeur.
                        </h4>
                      </div>
                      <div className="insideinside">
                        <div className="titre-projet">
                          <div className="insidetitreproject">
                            <a href="https://fahrat-kouki.com">Systeme</a>
                            <p>(Click)</p>
                          </div>
                        </div>
                        <div className="technologies-utilisees">
                          BabylonJS / HTML/ CSS/ JavaScript
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container2" onClick={handleCardClick2}>
              <div
                onClick={handleCardClick2}
                data-cursor-color="rgb(255, 255, 255,0.8)"
                className={`card ${isFlipped2 ? "flipped" : ""}`}
              >
                <div className="card-front">
                  <img
                    alt="Projet Mangacademy"
                    src="/Mangacademy.png"
                    className="insidecardfront"
                  ></img>
                </div>
                <div className="card-back">
                  <div className="insidecardback">
                    <div className="parent">
                      <div className="texte-projet">
                        <h4>
                          Ce projet ambitieux consiste en la création d'un
                          réseau social à thème, inspiré de la dynamique et de
                          l'interface utilisateur de Twitter. Ce projet vise à
                          offrir une expérience sociale en ligne unique, tout en
                          intégrant les fonctionnalités clés qui font le succès
                          des plateformes sociales modernes. Parmi ces
                          fonctionnalités, nous avons :
                          <br />
                          - Connexion/Inscription : Un processus
                          d'authentification fluide et sécurisé, permettant aux
                          utilisateurs de créer et d'accéder facilement à leurs
                          comptes.
                          <br />
                          - Publication de Posts : Les utilisateurs peuvent
                          partager leurs pensées, photos, et vidéos, engageant
                          ainsi avec la communauté de manière dynamique.
                          <br />
                          - Messagerie : Un système de messagerie intégré permet
                          aux utilisateurs de communiquer en privé, favorisant
                          des interactions plus personnalisées.
                          <br />
                          L'objectif principal de ce projet est de créer un
                          espace en ligne où les utilisateurs peuvent se
                          connecter et interagir autour d'un thème spécifique,
                          en s'inspirant de l'efficacité et de la convivialité
                          de Twitter. Ce projet représente non seulement une
                          opportunité de développer des compétences avancées en
                          matière de développement web et de gestion de
                          communauté en ligne, mais il témoigne également de ma
                          capacité à conceptualiser et à réaliser une plateforme
                          interactive et engageante.
                          <br />
                        </h4>
                      </div>
                      <div className="insideinside">
                        <div className="titre-projet">
                          <div className="insidetitreproject">
                            <a href="https://mangacademy.com">Mangacademy</a>
                            <p>(Click)</p>
                          </div>
                        </div>
                        <div className="technologies-utilisees">
                          React/Node/SQL
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container2" onClick={handleCardClick3}>
              <div
                onClick={handleCardClick3}
                data-cursor-color="rgb(255, 255, 255,0.8)"
                className={`card ${isFlipped3 ? "flipped" : ""}`}
              >
                <div className="card-front">
                  <img
                    alt="Projet Hero Archives"
                    src="/heroarchives.png"
                    className="insidecardfront"
                  ></img>
                </div>
                <div className="card-back">
                  <div className="insidecardback">
                    <div className="parent">
                      <div className="texte-projet">
                        <h4>
                          Ce projet innovant est une application web développée
                          avec VueJS, qui tire parti de l'API Marvel pour
                          présenter des informations détaillées sur une
                          multitude de héros issus de l'univers Marvel. Cette
                          application est conçue pour offrir une expérience
                          utilisateur immersive et interactive, permettant aux
                          fans et aux curieux de découvrir en profondeur les
                          personnages de cet univers riche et diversifié. Les
                          caractéristiques principales de ce projet incluent :
                          <br />
                          - Interface Intuitive : Grâce à VueJS, l'application
                          bénéficie d'une interface utilisateur réactive et
                          facile à naviguer, rendant l'accès aux informations à
                          la fois simple et agréable.
                          <br />
                          - Informations Détaillées : L'application extrait des
                          données complètes via l'API Marvel, offrant aux
                          utilisateurs des profils détaillés sur chaque héros,
                          incluant leur histoire, leurs pouvoirs, leurs
                          apparitions importantes, et bien plus.
                          <br />
                          - Mises à Jour Constantes : L'application est conçue
                          pour se mettre à jour automatiquement avec les
                          dernières informations disponibles, assurant ainsi que
                          les utilisateurs aient toujours accès aux données les
                          plus récentes.
                          <br />
                          Ce projet démontre non seulement mes compétences
                          techniques en développement web avec VueJS et en
                          intégration d'APIs, mais il reflète également ma
                          passion pour la création d'expériences numériques
                          captivantes et informatives. Il s'agit d'un exemple
                          parfait de la manière dont la technologie peut être
                          utilisée pour rapprocher les fans de leurs héros
                          favoris et enrichir leur compréhension de l'univers
                          Marvel.
                          <br />
                        </h4>
                      </div>
                      <div className="insideinside">
                        <div className="titre-projet">
                          <div className="insidetitreproject">
                            <a href="https://heroarchives.com">HeroArchives</a>
                            <p>(Click)</p>
                          </div>
                        </div>
                        <div className="technologies-utilisees">
                          VueJS / ExpressJS
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="space"></div>
    </motion.div>
  );
};
export default Home;
