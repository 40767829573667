import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [imageBase642, setImageBase642] = useState("");
  useEffect(() => {
    // Fetch part
    fetch("/images/img2.txt")
      .then((response) => response.text())
      .catch((error) => console.error("Error fetching img1:", error))
      .then((image) => setImageBase642(image));
  }, []);

  useEffect(() => {
    // Canvas part
    if (imageBase642) {
      const logo = document.getElementById("logo");
      const ctx = logo.getContext("2d");
      const myImage = new Image();
      myImage.src = imageBase642;

      myImage.onload = () => {
        let width = logo.width;
        let height = logo.height;
        logo.width = width;
        logo.height = height;
        class Particle {
          constructor(effect, x, y, color) {
            this.effect = effect;
            this.x = Math.random() * this.effect.width;
            this.y = 0;
            this.originX = Math.floor(x);
            this.originY = Math.floor(y);
            this.color = color;
            this.size = this.effect.gap;
            this.vx = 0;
            this.vy = 0;
            this.ease = 0.8;
            this.friction = 0.98;
            this.dx = 0;
            this.dy = 0;
            this.distance = 0;
            this.force = 0;
            this.angle = 0;
          }
          draw(context) {
            context.fillStyle = this.color;
            context.fillRect(this.x, this.y, this.size, this.size);
          }
          update() {
            this.dx = this.effect.mouse.x - this.x;
            this.dy = this.effect.mouse.y - this.y;
            this.distance = this.dx * this.dx + this.dy * this.dy;
            this.force = -this.effect.mouse.radius / this.distance;

            if (this.distance < this.effect.mouse.radius) {
              this.angle = Math.atan2(this.dy, this.dx);
              this.vx += this.force * Math.cos(this.angle);
              this.vy += this.force * Math.sin(this.angle);
            }
            this.x +=
              (this.vx *= this.friction) + (this.originX - this.x) * this.ease;
            this.y +=
              (this.vy *= this.friction) + (this.originY - this.y) * this.ease;
          }
        }
        class Effect {
          constructor(width, height) {
            this.width = width;
            this.height = height;
            this.particlesArray = [];
            this.image = myImage;
            this.centerX = this.width * 0.5;
            this.centerY = this.height * 0.5;
            this.x = this.centerX - logo.width * 0.5;
            this.y = this.centerY - logo.height * 0.5;
            this.gap = 1;
            this.mouse = {
              radius: 1000,
              x: undefined,
              y: undefined,
            };
            logo.addEventListener("mousemove", (event) => {
              const rect = logo.getBoundingClientRect();
              const scaleX = logo.width / rect.width;
              const scaleY = logo.height / rect.height;
              this.mouse.x = (event.clientX - rect.left) * scaleX;
              this.mouse.y = (event.clientY - rect.top) * scaleY;
            });
          }
          init(context) {
            context.drawImage(
              this.image,
              this.x,
              this.y,
              logo.width,
              logo.height
            );
            const pixels = context.getImageData(
              0,
              0,
              this.width,
              this.height
            ).data;
            for (let y = 0; y < this.height; y += this.gap) {
              for (let x = 0; x < this.width; x += this.gap) {
                const index = (y * this.width + x) * 4;
                const red = pixels[index];
                const green = pixels[index + 1];
                const blue = pixels[index + 2];
                const alpha = pixels[index + 3];
                const color = "rgb(" + red + "," + green + "," + blue + ")";
                if (alpha > 0) {
                  this.particlesArray.push(new Particle(this, x, y, color));
                }
              }
            }
          }
          draw(context) {
            this.particlesArray.forEach((particle) => particle.draw(context));
          }
          update() {
            this.particlesArray.forEach((particle) => particle.update());
          }
          warp() {
            this.particlesArray.forEach((particle) => particle.warp());
          }
        }
        const effect = new Effect(logo.width, logo.height);
        effect.init(ctx);

        function animate() {
          ctx.clearRect(0, 0, logo.width, logo.height);
          effect.draw(ctx);
          effect.update();
          requestAnimationFrame(animate);
        }
        animate();
      };
    }
  }, [imageBase642]);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <nav className="navigation">
      <div src="/mobilemenu.png" id="logo-container" onClick={toggleMobileMenu}>
        {/* Mobile menu icon */}
        <div className={`menu-icon ${isMobileMenuOpen ? "open" : ""}`}>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <canvas id="logo"></canvas>

      <ul className={`${isMobileMenuOpen ? "mobile-open" : ""}`}>
        <li>
          <Link to="/">
            <p>Home</p>
          </Link>
        </li>
        <li>
          <Link to="/others">
            <p>Other Projects</p>
          </Link>
        </li>
        <li>
          <Link to="/about">
            <p>About Me</p>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
