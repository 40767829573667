import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./Components/Home";
import Others from "./Components/Others";
import Navbar from "./Components/Navbar";
import About from "./Components/About";

import { AnimatePresence } from "framer-motion";
import { Cursor } from "react-creative-cursor";
import "react-creative-cursor/dist/styles.css";

const App = () => {

  return (
    <Router>
      <Navbar />
      <RouteRender />
    </Router>
  );
};

const RouteRender = () => {
  const location = useLocation();
  useEffect(() => {
    const cursor = document.querySelector(".cursor2");

    const moveCursor = (e) => {
      const x = e.pageX;
      const y = e.pageY;
      if (cursor) {
        cursor.style.left = x + "px";
        cursor.style.top = y + "px";
      }
    };

    document.addEventListener("mousemove", moveCursor);

    return () => {
      document.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <AnimatePresence mode="wait">
      <div className="cursor2"></div>
      <Cursor key="a" isGelly={true} />
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/others" element={<Others />} />
        <Route path="/About" element={<About />} />
      </Routes>
    </AnimatePresence>
  );
};

export default App;
